<template>
  <div
    class="p-0 m-0"
    :style="
      $root.mobile ? 'margin-top:0px !important' : 'margin-top:-30px !important'
    "
  >
   <!-- <PulltoRefresh ref="refresh" @dorefresh="refreshaction" />-->
    <div v-if="isComplete">
      <div class="row">
        <div v-if="$root.mobile" class="col-lg-4 col-xl-4 col-sm-6 py-0 mb-3">
          <div class="mb-2 listitem lglistitem" style="min-height:100px;">
            <div class="p-1 px-6 flex-grow-1" style="z-index: 1;">
              <h4 class="text-primary font-weight-bolder">
                {{$t("DASHBOARD.PATIENT.next.title")}}
              </h4>
              <template v-if="nextapp">
                <p
                  v-if="nextapp.length > 0"
                  class="text-dark-50 font-weight-bold mt-3"
                >
                  {{
                    shownextapptime(nextapp[0].date_time)
                  }}
                </p>
                <span v-else class="text-dark-50 font-weight-bold mt-3">
                  {{$t("DASHBOARD.PATIENT.next.noavail")}}
                </span>
              </template>
              <a
                v-else-if="nextapp != null && nextapp.status == 'progress'"
                class="btn btn-link btn-link-primary font-weight-bold"
                >{{$t("DASHBOARD.PATIENT.next.action")}}
              </a>
              <span v-else class="text-dark-50 font-weight-bold mt-3">
                {{$t("DASHBOARD.PATIENT.next.loading")}}
              </span>
            </div>
            <lottie-animation
              :animationData="require('@/assets/animation/lf20_dy6ooa7u.json')"
              :loop="true"
              style=""
              :class="'cardanime'"
            />
          </div>
        </div>
        <div v-if="!$root.mobile" class="col-lg-4 col-xl-4 col-sm-6 py-0 mt-8">
          <!--begin::Engage next appointment alert -->

          <div class="card card-custom card-stretch gutter-b">
            <div class="card-body p-0 d-flex">
              <div
                class="d-flex align-items-start justify-content-start flex-grow-1 bg-light-primary p-8 card-rounded flex-grow-1 position-relative"
              >
                <div
                  class="d-flex flex-column align-items-start flex-grow-1 h-100"
                >
                  <div class="p-1 flex-grow-1">
                    <h4 class="text-primary font-weight-bolder">
                      {{$t("DASHBOARD.PATIENT.next.title")}}
                    </h4>
                    <template v-if="nextapp">
                      <p
                        v-if="nextapp.length > 0"
                        class="text-dark-50 font-weight-bold mt-3"
                      >
                        {{
                          (nextapp[0].date_time + "Z")
                            | moment("timezone", getTimeZone(), "from", "now")
                        }}
                      </p>
                      <span v-else class="text-dark-50 font-weight-bold mt-3">
                        {{$t("DASHBOARD.PATIENT.next.noavail")}}
                      </span>
                    </template>
                    <span v-else class="text-dark-50 font-weight-bold mt-3">
                      {{$t("DASHBOARD.PATIENT.next.action")}}
                    </span>
                  </div>
                  <a
                    v-if="nextapp != null && nextapp.status == 'progress'"
                    class="btn btn-link btn-link-primary font-weight-bold"
                    >{{$t("DASHBOARD.PATIENT.next.action")}}
                  </a>
                </div>
                <div
                  class="position-absolute right-0 bottom-0 mr-5 overflow-hidden"
                >
                  <img
                    src="/media/svg/humans/custom-13.svg"
                    :class="
                      $root.mobile
                        ? 'max-h-100px'
                        : 'max-h-100px max-h-xl-200px mb-n12'
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Engage next appointment alert -->
        </div>
        <div
          v-if="!$root.mobile"
          class="col-lg-4 col-xl-4 col-sm-6 py-0 mt-8"
          :style="$root.mobile ? 'margin-top: -15px;' : ''"
        >
          <!--begin::Engage question -->
          <div class="card card-custom card-stretch gutter-b">
            <div class="card-body p-0 d-flex">
              <div
                class="d-flex align-items-start justify-content-start flex-grow-1 bg-light-primary p-8 card-rounded flex-grow-1 position-relative"
              >
                <div
                  class="d-flex flex-column align-items-start flex-grow-1 h-100"
                >
                  <div class="p-1 flex-grow-1">
                    <h4 class="text-primary font-weight-bolder">
                      {{$t("DASHBOARD.PATIENT.advice.title")}}
                    </h4>
                    <p class="text-dark-50 font-weight-bold mt-3">
                      {{$t("DASHBOARD.PATIENT.advice.prompt")}}
                    </p>
                  </div>
                  <a
                    @click="showaddQ = !showaddQ"
                    class="btn btn-link btn-link-primary font-weight-bold"
                    >{{$t("DASHBOARD.PATIENT.advice.action")}}
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                            x="11"
                            y="5"
                            width="2"
                            height="14"
                            rx="1"
                          ></rect>
                          <path
                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 
                                  8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 
                                  22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                          ></path>
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span></a
                  >
                </div>
                <div
                  class="position-absolute right-0 bottom-0 mr-5 overflow-hidden"
                >
                  <img
                    src="/media/svg/illustrations/copy.svg"
                    :class="
                      $root.mobile
                        ? 'max-h-100px'
                        : 'max-h-100px max-h-xl-200px mb-n12 mr-n10'
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Engage next qustion-->
        </div>
        <div
          v-if="!$root.mobile"
          class="col-lg-4 col-xl-4 col-sm-12 py-0 mt-8"
          :style="$root.mobile ? 'margin-top: -15px;' : ''"
        >
          <!--begin::Engage question -->
          <div class="card card-custom card-stretch gutter-b">
            <div class="card-body p-0 d-flex">
              <div
                class="d-flex align-items-start justify-content-start flex-grow-1 bg-light-primary p-8 card-rounded flex-grow-1 position-relative"
              >
                <div
                  class="d-flex flex-column align-items-start flex-grow-1 h-100"
                >
                  <div class="p-1 flex-grow-1">
                    <h4 class="text-primary font-weight-bolder">
                      {{$t("DASHBOARD.PATIENT.book.title")}}
                    </h4>
                    <p class="text-dark-50 font-weight-bold mt-3">
                      {{$t("DASHBOARD.PATIENT.book.prompt")}}
                    </p>
                  </div>
                  <a
                    @click="openAddAppointment()"
                    class="btn btn-link btn-link-primary font-weight-bold"
                    >{{$t("DASHBOARD.PATIENT.book.action")}}
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon | path:/metronic/theme/html/demo1/dist/assets/media/svg/icons/Navigation/Arrow-right.svg-->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <polygon points="0 0 24 0 24 24 0 24"></polygon>
                          <rect
                            fill="#000000"
                            opacity="0.3"
                            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
                            x="11"
                            y="5"
                            width="2"
                            height="14"
                            rx="1"
                          ></rect>
                          <path
                            d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 
                                  8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 
                                  22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                            fill="#000000"
                            fill-rule="nonzero"
                            transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)"
                          ></path>
                        </g>
                      </svg>
                      <!--end::Svg Icon-->
                    </span></a
                  >
                </div>
                <div
                  class="position-absolute right-0 bottom-0 mr-5 overflow-hidden"
                >
                  <img
                    src="/media/svg/humans/custom-1.svg"
                    :class="
                      $root.mobile
                        ? 'max-h-100px'
                        : 'max-h-100px max-h-xl-200px mb-n12'
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <!--end::Engage next qustion-->
        </div>

        <div v-if="$root.mobile" class="col-12 py-0">
          <h4 class="text-primary font-weight-bolder px-1 py-2">
            {{$t("DASHBOARD.PATIENT.departments.title")}}
          </h4>
          <div v-if="departments" class="row grid">
            <div class="col-6">
              <div
                class="griditem"
                @click="
                  dSelected = departments[0];
                  openAddAppointment(departments[0]);
                "
              >
                <div class="griditemicon">
                  <img
                    width="25"
                    :src="
                      $root.assetsBaseURL + departments[0].avatar.data.asset_url
                    "
                  />
                </div>
                <div class="mt-3">
                  <div class="griditemtitle">
                    {{ getgriditemtitle(departments[0]) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pl-2 pb-2">
              <div
                class="griditem"
                @click="
                  dSelected = departments[3];
                  openAddAppointment(departments[3]);
                "
              >
                <div class="griditemicon">
                  <img
                    width="25"
                    :src="
                      $root.assetsBaseURL + departments[3].avatar.data.asset_url
                    "
                  />
                </div>
                <div class="mt-3">
                  <div class="griditemtitle">
                    {{ getgriditemtitle(departments[3]) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pr-2 pt-2">
              <div
                class="griditem"
                @click="
                  dSelected = departments[2];
                  openAddAppointment(departments[2]);
                "
              >
                <div class="griditemicon">
                  <img
                    width="25"
                    :src="
                      $root.assetsBaseURL + departments[2].avatar.data.asset_url
                    "
                  />
                </div>
                <div class="mt-3">
                  <div class="griditemtitle">
                    {{ getgriditemtitle(departments[2]) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-6 pl-2 pt-2">
              <div class="griditem" @click="openAddAppointment()">
                <div class="griditemicon">
                  <img src="/media/svg/icons/Doctory/more.svg" />
                </div>
                <div class="mt-3">
                  <div class="griditemtitle">{{$t("DASHBOARD.PATIENT.departments.action")}}</div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="row">
            <div class="col-6 pr-2 pb-2">
              <div class="skeleton-box"></div>
            </div>
            <div class="col-6 pl-2 pb-2">
              <div class="skeleton-box"></div>
            </div>
            <div class="col-6 pr-2 pt-2">
              <div class="skeleton-box"></div>
            </div>
            <div class="col-6 pl-2 pt-2">
              <div class="skeleton-box"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 py-0">
          <h4 class="text-primary font-weight-bolder px-1 py-2">
            {{$t("DASHBOARD.PATIENT.upcoming.title")}}
          </h4>
          <AppointmentUpcoming
            v-if="nextapp != null && nextapp.length > 0"
            :reload="reloadupcoming"
            :skltoload="1"
          />
          <div
            v-else
            style="width:100%;display: flex;
    justify-content: center;"
            class="mt-3"
          >
            <v-btn
              color="primary"
              @click="openAddAppointment()"
              rounded
              elevation="0"
              large
            >
              {{$t("DASHBOARD.PATIENT.upcoming.action")}}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <AddWrapper
      :dPreSelection="dSelected"
      @addnewapp="reloadupcoming++"
      ref="add"
    ></AddWrapper>
    <AddQ
      v-if="!loadingStatus"
      :dialog="showaddQ"
      :departments="departments"
      @closeClicked="showaddQ = false"
    ></AddQ>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import moment from 'moment-timezone';
import AppointmentUpcoming from "@/view/pages/patient/comp/appointments/upcoming.vue";
import AddWrapper from "@/view/pages/patient/comp/appointments/addwrapper.vue";
import AddQ from "@/view/pages/patient/comp/questions/quadd.vue";
import LottieAnimation from "lottie-web-vue";

export default {
  name: "PatientDashboard",
  components: {
    AppointmentUpcoming,
    AddWrapper,
    AddQ,
    LottieAnimation,
    //PulltoRefresh: () => import('@/view/pages/utilComponents/pulltorefresh.vue')
  },
  data() {
    return {
      isComplete: true,
      userInfo: "",
      mdFile: {},
      reloadupcoming: 0,
      showadd: false,
      showaddQ: false,
      loadingStatus: true,
      loadingnext: true,
      nextapp: null,
      departments: null,
      dSelected: null,
      closerefresh:false,
    };
  },

  methods: {
    shownextapptime(time)
    {
      //(nextapp[0].date_time + "Z")
      //                | moment("timezone", getTimeZone(), "from", "now")
      this.$moment.locale(this.$root.lang)
      return this.$moment(time + " Z")
            .tz(this.getTimeZone())
            .fromNow()
    },
    openAddAppointment(d = null)
    {
      if(d!= null)
        this.$root.patient.selectedDepartment = d;
      this.$refs.add.doOpen(d);
    },
   refreshaction()
   {
     setTimeout(() => {
          this.$refs.refresh.closerefresh();
        }, 4000);
   },
    getTimeZone() {
      return localStorage.timezone;
    },
    // getTimeZone() {
    //   return localStorage.timezone;
    // },
    async getPatientMF() {
      try {
        const { data } = await ApiService.get(
          "items",
          `medical_file?filter[owner]=${this.userInfo}`
        );

        this.$root.mf = data.data[0].id;
      } catch (error) {
        console.error(error);
      }
    },
    async getDepartments() {
      await ApiService.get(
        "items",
        "department?fields=id,name,avatar.data,description,translation.*,doctors.*.*.*"
      )
        .then(({ data }) => {
          this.departments = data.data;
          this.loadingStatus = false;
        })
        .catch((error) => {
          this.error = error;
        });
    },
    getgriditemtitle(d) {
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i].name;
          }
        }
      } else {
        return d.name;
      }
    },
    async getUpcomingAppointment() {
      this.$moment.locale("en");
      var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "appointments?fields=id,status,video_room_id,rating,type,date,time,date_time,medical_file," +
          "doctor.doctor_id.first_name,doctor.doctor_id.last_name,doctor.doctor_id.avatar.data,doctor.doctor_id.id," +
          "rating&filter[medical_file.owner]=" +
          userInfo +
          "&filter[status][in]=progress,pending&sort=date_time&limit=1" 
      )
        .then(({ data }) => {
          console.log("upcoming next is",data.data);
          this.nextapp = data.data;
          this.loadingnext = false;
          this.$moment.locale(localStorage.language);
        })
        .catch((error) => {
          this.error = error;
        });
    },

    
  },
  activated()
  {
    this.getUpcomingAppointment();
  },
  mounted() {

    if (localStorage.userInfo) {
      this.userInfo = JSON.parse(localStorage.userInfo).id;
    }
    this.getPatientMF();
    this.getDepartments();
    this.getUpcomingAppointment();
  },
};
</script>

<style></style>

<style lang="scss" scoped>
.skeleton-box {
  height: 140px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
