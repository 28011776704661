<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    width="500px"
    persistent
    style="min-height:60% !important"
    v-if="!$root.mobile"
  >
    <v-card :disabled="askStarted">
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("QUES.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="questionForm" v-model="valid">
          <v-combobox
            v-model="selectedDepart"
            :items="departments"
            :label="$t('QUES.selectDeprt')"
            outlined
            item-text="name"
            item-value="name"
            hide-selected
          >
            <template v-slot:selection="{ item }">
              {{ getTranslation(item).name }}
            </template>
            <template v-slot:item="{ index, item }">
              {{ index }} {{ getTranslation(item).name }}
            </template>
          </v-combobox>
          <v-text-field :label="$t('QUES.subject')" v-model="title" :rules="titleRule" outlined />
          <v-textarea
            outlined
            :label="$t('QUES.writehere')"
            auto-grow
            v-model="question"
            :rules="detailsRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="pb-4"
        style="display:flex;width:100%;justify-content: center;align-items: center;"
      >
        <v-btn
          width="46%"
          dark
          elevation="0"
          large
          rounded
          color=" red"
          @click="close()"
        >
          {{ $t("QUES.cancel") }}
        </v-btn>
        <v-btn
          width="46%"
          elevation="0"
          rounded
          large
          color="primary"
          @click="submit"
        >
          {{ $t("QUES.ask") }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="askStarted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t("APPOINTMENTS.add.booking") }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <v-bottom-sheet v-model="dialog" v-else>
    <v-card :disabled="askStarted">
      <v-toolbar flat dark color="white" class="text-primary">
        <v-toolbar-title>{{ $t("QUES.new") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon dark color="primary" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="min-height: 90%" class="pt-5 pb-0">
        <v-form ref="questionForm" v-model="valid">
          <v-combobox
            v-model="selectedDepart"
            :items="departments"
            :label="$t('QUES.selectDeprt')"
            outlined
            item-text="name"
            item-value="name"
            hide-selected
          >
            <template v-slot:selection="{ item }">
              {{ getTranslation(item).name }}
            </template>
            <template v-slot:item="{ index, item }">
              {{ index }} {{ getTranslation(item).name }}
            </template>
          </v-combobox>
          <v-text-field
            :label="$t('QUES.subject')"
            v-model="title"
            :rules="titleRule"
            outlined
          />
          <v-textarea
            outlined
            :label="$t('QUES.writehere')"
            auto-grow
            v-model="question"
            :rules="detailsRules"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions
        class="pb-4"
        style="display:flex;width:100%;justify-content: center;align-items: center;"
      >
        <v-btn
          width="46%"
          dark
          elevation="0"
          large
          rounded
          color=" red"
          @click="close()"
        >
          {{ $t("QUES.cancel") }}
        </v-btn>
        <v-btn
          width="46%"
          elevation="0"
          rounded
          large
          color="primary"
          @click="submit"
        >
          {{ $t("QUES.ask") }}
        </v-btn>
      </v-card-actions>

      <v-dialog v-model="askStarted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            {{ $t("APPOINTMENTS.add.booking") }}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import AvatarSM from "@/view/pages/utilComponents/avatar1.vue";

export default {
  name: "QuAdd",
  props: ["dialog", "departments"],
  components: {},
  data() {
    return {
      error: "",
      question: "",
      title: "",
      selectedDepart: this.departments[0],
      askStarted: false,
      valid: true,
      titleRule: [(v) => !!v || this.$t("QUES.validation.required")],
      detailsRules: [
        (v) => !!v || this.$t("QUES.validation.required"),
        (v) =>
          (v && v.trim().length >= 10) ||
          this.$t("QUES.validation.length"),
      ],
    };
  },
  mounted() {},
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async submit() {
      if (this.valid) {
        this.askStarted = true;
        var payload = {
          question: this.question,
          title: this.title,
          department: this.selectedDepart.id,
          status: "notanswered",
        };
        await ApiService.post("items/question", payload)
          .then(({ data }) => {
            console.log("question is", data.data);
            this.askStarted = false;
            this.close();
          })
          .catch((error) => {
            this.error = error;
          });
      }
    },

    close() {
      this.question = "";

      this.$emit("closeClicked", false);
    },
  },
  computed: {},
};
</script>
<style></style>
